/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'

import { shuffle, getStreamUrlFromTag } from '../utils/helpers'
import { ArticleTags } from '../../types'

interface ArticleRelatedTopicsProps {
    tags: ArticleTags
    isPartnerContent: boolean
}

const ArticleRelatedTopics: FC<ArticleRelatedTopicsProps> = ({
    tags = {},
    isPartnerContent = false,
}) => {
    // const [topics, setTopics] = useState([])

    // useEffect(() => {
    //     setTopics(getRelatedTopicsByTags(tags))
    // }, [tags])

    const topics = getRelatedTopicsByTags(tags)

    if (topics.length === 0) {
        return null
    }

    const colspan = isPartnerContent
        ? '12 Mpush1 Lpush2 M10 L8'
        : '12 Mpush1 M10 L7'

    return (
        <>
            <div className='article-related-topics o-grid-container'>
                <div className='o-grid-row'>
                    <div data-o-grid-colspan={colspan}>
                        <h3 className='article-related-topics__title'>
                            Related topics
                        </h3>
                    </div>
                </div>
                <div className='o-grid-row'>
                    <div data-o-grid-colspan={colspan}>
                        {/* filter duplicates by tag.label */}
                        {topics
                            .filter(
                                (tag: any, index, self: any) =>
                                    self.findIndex(
                                        (t: any) => t.label === tag.label
                                    ) === index
                            )
                            .map((tag: any) => {
                                // eslint-disable-next-line no-unused-expressions
                                tag.label === 'Advertorial'
                                    ? 'Partner Content'
                                    : tag.label
                                const href = getStreamUrlFromTag(tag)
                                return (
                                    <a
                                        className='article-related-topics__topic'
                                        href={href as string}
                                        key={tag.label}
                                        data-trackable='Related Topic'
                                    >
                                        {tag.label === 'Advertorial'
                                            ? 'Partner Content'
                                            : tag.label}
                                    </a>
                                )
                            })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArticleRelatedTopics

function getRelatedTopicsByTags(tags: ArticleTags) {
    const result = Object.keys(tags).reduce((topics, key) => {
        if (
            !['sections', 'nominated', '__typename'].includes(key) &&
            tags[key as keyof ArticleTags] !== null
        ) {
            if (Array.isArray(tags[key as keyof ArticleTags])) {
                return topics.concat(tags[key as keyof ArticleTags] as [])
            }
            if (typeof tags[key as keyof ArticleTags] === 'object') {
                return topics.concat([tags[key as keyof ArticleTags] as never])
            }
        }

        return topics
    }, [])

    return shuffle(result)
}
