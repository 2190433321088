import React, { FC } from 'react'
import { useBrightcoveScriptInit } from '../../utils/videos'

interface ArticleVideo {
    accountId?: string
    playerId?: string
    playerUrl?: string
    videoId?: string
    title?: string
    vimeoMigrationEnabled?: string | null
    vimeoPlayerUrl?: string
}

const ArticleVideo: FC<ArticleVideo> = (
    props = {
        accountId: '',
        playerId: '',
        playerUrl: '',
        videoId: '',
        title: '',
        vimeoMigrationEnabled: null,
        vimeoPlayerUrl: '',
    }
) => {
    useBrightcoveScriptInit(
        props.playerUrl || '',
        props.accountId || '',
        props.playerId || '',
        props.vimeoMigrationEnabled || null
    )

    return (
        <>
            {props.vimeoMigrationEnabled === 'true' ? (
                <div
                    style={{
                        padding: '48.45% 0px 0px',
                        marginBottom: '2rem',
                        position: 'relative',
                    }}
                >
                    <iframe
                        src={`${props.vimeoPlayerUrl}${props.videoId}`}
                        frameBorder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: 'calc(100% - 20px)',
                        }}
                        title={props.title || ''}
                    />
                </div>
            ) : (
                <div className='article-header__video o-grid-row'>
                    <div data-o-grid-colspan='12 Mpush1 M10 L11'>
                        <div className='article-header__brightcove-outer'>
                            <div className='article-header__brightcove-inner'>
                                <video
                                    data-video-id={props.videoId}
                                    data-account={props.accountId}
                                    data-player={props.playerId}
                                    data-embed='default'
                                    data-application-id
                                    className='video-js article-header__brightcove-video'
                                    controls
                                    aria-labelledby={`video-title--${props.videoId}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ArticleVideo
