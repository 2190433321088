import React from 'react'

// import getConfig from 'next/config'

import ArticleBanner from '../../../article-banner/ArticleBanner'
import ArticleMetadata from '../../../article-metadata/ArticleMetadata'
import ArticleSocialMediaButtons from '../../../article-social-media-buttons/ArticleSocialMediaButtons'
import ArticleTeaser from '../../../article-teaser/ArticleTeaser'
import ArticleTitle from '../../../article-title/ArticleTitle'
import ArticleVideo from '../../../article-video/ArticleVideo'
import ArticleSponsors from '../../../sponsors/Sponsors'
import Tag from '../../../tag/Tag'
import { Article } from '../../../../types'
import Hydration from '../../../Hydration'

// const { publicRuntimeConfig } = getConfig()

interface ArticleHeaderVideoProps {
    article: Article
    environment: {
        vimeoMigrationEnabled: string
        vimeoPlayerUrl: string
        brightcovePlayerId: string
        brightcoveAccountId: string
        brightcovePlayerUrl: string
    }
    colour: string
    type: string
}

const ArticleHeaderVideo = (props: ArticleHeaderVideoProps) => {
    const {
        title,
        teaser,
        author,
        advertorial_sponsor,
        publication_date,
        videoid,
    } = props.article

    return (
        <div className='article-header article-header-video'>
            <ArticleBanner article={props.article} colour={props.colour}>
                <ArticleTitle title={title} white superText={props.type} />
                {props.type === 'onlocation' && (
                    <Tag type='sponsored' colspan='Mpush1' />
                )}
                <ArticleTeaser teaser={teaser} white />
                <Hydration id='article-header-video'>
                    <ArticleVideo
                        accountId={props.environment.brightcoveAccountId}
                        playerId={props.environment.brightcovePlayerId}
                        playerUrl={props.environment.brightcovePlayerUrl}
                        vimeoMigrationEnabled={
                            props.environment.vimeoMigrationEnabled
                        }
                        vimeoPlayerUrl={props.environment.vimeoPlayerUrl}
                        videoId={videoid}
                    />
                </Hydration>
                <ArticleSponsors sponsors={advertorial_sponsor} />
            </ArticleBanner>
            <div className='article__social-media-container'>
                <div className='o-grid-container'>
                    <Hydration id='article-social-media-buttons'>
                        <ArticleSocialMediaButtons
                            title={title}
                            teaser={teaser}
                            type='video'
                        />
                    </Hydration>
                </div>
                <ArticleMetadata date={publication_date} author={author} />
            </div>
        </div>
    )
}

export default ArticleHeaderVideo
