import { Article } from '../types'
import typesConfig from '../assets/articleTypesConfig'
import colours from '../utils/colours'

export const GENRE_ARTICLE_TYPES = {
    Podcast: 'Podcast',
    Video: 'video',
    'Rankings and awards': 'rankings',
    Advertorial: 'advertorial',
} as const

export const BRAND_ARTICLE_TYPES = {
    Advertorial: 'advertorial',
    'Inside fDi': 'inside',
    'The Global Lawyer': 'globalLawyer',
    'Free Zone Focus': 'freezone',
    'View from Americas': 'americas',
    'View from Europe': 'europe',
    'View from Asia-Pacific': 'asia',
    'View from Middle East & Africa': 'middleEast',
} as const

export function getGenreAndBrand(article: Article) {
    const genre =
        article &&
        article.tags &&
        article.tags.genre &&
        article.tags.genre.label
            ? article.tags.genre.label
            : ''
    const brand =
        article &&
        article.tags &&
        article.tags.brand &&
        article.tags.brand.label
            ? article.tags.brand.label
            : ''

    return [genre, brand]
}

export function getArticleType(genre: string, brand: string) {
    let type = (GENRE_ARTICLE_TYPES[
        genre as keyof typeof GENRE_ARTICLE_TYPES
    ] ||
        BRAND_ARTICLE_TYPES[
            brand as keyof typeof BRAND_ARTICLE_TYPES
        ]) as string

    if (genre === 'Opinion' && type == null) {
        type = 'generalOpinion'
    }

    return type || ''
}

export function getColour(articleType: string) {
    const colourString = typesConfig[articleType as keyof typeof typesConfig]
        ? typesConfig[articleType as keyof typeof typesConfig].colour
        : null
    const colour = colours.find(colour => {
        return colour.name === colourString
    })

    return colour ? colour.hex : null
}
