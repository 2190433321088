import React, { FC } from 'react'
import { SponsorType } from '../../types'

interface PartnersProps {
    partners: Array<SponsorType>
    colSpan?: string
}

const Partners: FC<PartnersProps> = (
    { partners, colSpan } = {
        partners: [],
        colSpan: '',
    }
) => {
    if (!partners || partners.length === 0) {
        return null
    }
    const gridColspan = colSpan || '12 Mpush1 M10 L10'

    const textContainerClassName = [
        'partners__text-container',
        ...(partners.length === 1
            ? ['partners__text-container--single-partner']
            : []),
    ].join(' ')

    // const url = partners[0].link[0] && partners[0].link[0].indexOf('http') === 0 ? partners[0].link[0] : `https://${partners[0].link[0]}`

    return (
        <>
            <div className='partners o-grid-row'>
                <div
                    className={textContainerClassName}
                    data-o-grid-colspan={gridColspan}
                >
                    <span className='partners__text bold'>
                        <>PARTNER CONTENT</>
                        <span className='by'>BY</span>
                        <span className='partner-name'>{partners[0].name}</span>
                        <div className='popover-container'>
                            <i className='icon-question-mark'>?</i>
                            <div className='popover'>
                                {partners[0].name === 'Diriyah Company' && (
                                    <>
                                        This advertisement has been produced by
                                        the commercial department of the{' '}
                                        <strong>Financial Times</strong> on
                                        behalf of{' '}
                                        <strong>{partners[0].name}</strong>
                                    </>
                                )}
                                {partners[0].name !== 'Diriyah Company' && (
                                    <>
                                        This content was paid for and produced
                                        by <strong>{partners[0].name}</strong>
                                    </>
                                )}
                            </div>
                        </div>
                    </span>
                </div>
            </div>
        </>
    )
}

export default Partners
