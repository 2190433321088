import { useEffect } from 'react'

export const useBrightcoveScriptInit = (
    playerUrl: string,
    accountId: string,
    playerId: string,
    vimeoMigrationEnabled: string | null = null
) => {
    useEffect(() => {
        const script = window.document.createElement('script')

        script.src = `${playerUrl}/${accountId}/${playerId}_default/index.min.js`
        script.async = true
        if (vimeoMigrationEnabled !== 'true') {
            window.document.body.appendChild(script)
        }
    }, [playerUrl, accountId, playerId, vimeoMigrationEnabled])
}
