/* eslint-disable no-unused-vars, camelcase */
import React, { FC, useEffect } from 'react'
import { Article as ArticleType, MostReadArticle } from '../../types'
import ArticleHeader from './ArticleHeader'
import ArticleBody from '../article-body/ArticleBody'
import ArticleRelatedTopics from '../article-related-topics/ArticleRelatedTopics'
import Hydration from '../Hydration'
import { initTracking } from '../tracking/Tracking'
import { updateAdsConfig } from '../utils/updateAdsConfig'

const Article: FC<{
    // TODO: useContext
    article: ArticleType
    genre: string
    articleType: string
    colour: string | null
    brand: string
    mostRead: MostReadArticle[]
    environment: {
        vimeoMigrationEnabled: string
        vimeoPlayerUrl: string
        brightcovePlayerId: string
        brightcoveAccountId: string
        brightcovePlayerUrl: string
        apolloServerUrl: string
    }
}> = ({
    article,
    environment,
    mostRead,
    articleType,
    colour,
    genre,
    brand,
}) => {
    let tagType = articleType

    if (genre === 'Opinion' && articleType === '') {
        tagType = 'generalOpinion'
    }

    useEffect(() => {
        initTracking('article', { sitemap: `${genre}|${brand}` })
    }, [])

    useEffect(() => {
        if (article) {
            updateAdsConfig({
                uuid: article.id,
                section_tag: genre,
                asset_type: 'content',
                pt: 'art',
            })
        }
    }, [article, genre, articleType])

    return (
        <>
            <ArticleHeader
                type={tagType}
                article={article}
                colour={colour}
                environment={environment}
            />
            <Hydration id='article-body'>
                <ArticleBody
                    article={article}
                    mostRead={mostRead}
                    colour={colour}
                    genre={genre}
                    environment={environment}
                />
            </Hydration>
            <ArticleRelatedTopics
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tags={article.tags as any}
                isPartnerContent={!!article.partner_content}
            />
        </>
    )
}
export default Article
