export const DEFAULT_TITLE =
    'fDi Intelligence – Your source for foreign direct investment information - fDiIntelligence.com'

export const PAGE_TITLE_COMMERCIAL = 'Commercial'
export const PAGE_TITLE_FIND_NEW_INVESTORS = 'Find new investors'
export const PAGE_TITLE_FIND_NEW_CUSTOMERS = 'Find new customers'
export const PAGE_TITLE_REQUEST_DEMO = 'Request a free demo'
export const PAGE_TITLE_CONTACT_US = 'Contact us'
export const PAGE_TITLE_COMMISSION_VIDEO = 'Commission a video'
export const PAGE_TITLE_FDI_BENCHMARK = 'fDi Benchmark'
export const PAGE_TITLE_FDI_MARKETS = 'fDi Markets'
export const PAGE_TITLE_GIS_PLANNING = 'GIS Planning solutions'
export const PAGE_TITLE_PRODUCTS_SERVICES = 'Our products & services'
export const PAGE_TITLE_PROMOTE_LOCATION = 'Ways to promote your location'
export const PAGE_TITLE_RESEARCH_LOCATIONS = 'Location news and data'
export const PAGE_TITLE_NEW_PRODUCTS = 'New products'
export const PAGE_TITLE_ADVISORY_BOARD = 'Editorial Advisory Board'
export const PAGE_TITLE_MEET_THE_TEAM = 'Meet the team'
export const PAGE_TITLE_NEWSLETTER_SIGNUP =
    'Stay up-to-date with the latest trends driving crossborder investment'

export const FDI_INTELLIGENCE_STYLED = 'fDi Intelligence'
export const FDI_INTELLIGENCE_PROMO = {
    TITLE: 'Drive investment to your location',
    SUMMARY:
        'Become an industry leader amongst your competitors with our portfolio of services.',
}

export const FDI_INTELLIGENCE_PROMO_FT_LOCATIONS = {
    TITLE: 'Trust insight over instinct',
    SUMMARY: 'Decisions driven by intelligence',
}

export const FDI_INTELLIGENCE_TRACKING = 'fDi Intelligence'
export const FDI_INTELLIGENCE_TOOLPROMO = 'fdi-intelligence'

export const FDI_MARKETS_STYLED = 'fDi Markets'
export const FDI_MARKETS_PROMO = {
    TITLE: 'Identify and profile target investors',
    SUMMARY:
        'Stay ahead of the competition with access to real-time investment data plus new investor signals each month.',
}
export const FDI_MARKETING_TRACKING = 'fDi Markets'
export const FDI_MARKETING_TOOLPROMO = 'fdi-markets'
export const FDI_MARKETING_FORMTYPE = 'fdi-markets'

export const FDI_BENCHMARK_STYLED = 'fDi Benchmark'
export const FDI_BENCHMARK_PROMO = {
    TITLE: 'Benchmark potential investment locations',
    SUMMARY:
        'Carry out detailed, data-driven benchmarking studies on your shortlisted locations.',
}
export const FDI_BENCHMARK_TRACKING = 'fDi Benchmark'
export const FDI_BENCHMARK_TOOLPROMO = 'fdi-benchmark'
export const FDI_BENCHMARK_FORMTYPE = 'fdi-benchmark'

export const GIS_PLANNING_STYLED = 'GIS Planning'
export const GIS_PLANNING_PROMO = {
    TITLE: "Ensure your location's site selection data is on the map",
    SUMMARY:
        'Allow companies to instantly access the deepest available data on your location.',
}
export const GIS_PLANNING_TRACKING = 'GIS Planning'
export const GIS_PLANNING_TOOLPROMO = 'gis-planning'
export const GIS_PLANNING_FORMTYPE = 'gis-planning'

export const PAGE_TITLE_CREATE_EVENT = 'Create your own event'

export const TALK_ABOUT_EVENTS = {
    title: 'Talk to us about events',
    link: '/form/contact-us/Events',
}

export const CONTACT_US_EVENTS = {
    title: 'Contact us',
    link: '/form/contact-us/Events',
}

export const CONTACT_US_FORMTYPE = 'contact-us'

export const NEWSLETTER_SIGNUP_FORMTYPE = 'newsletter-signup'

export const DISCOVER_SERVICES_BUTTON_DATA = {
    title: 'Discover our services',
    link: '/products-and-services',
}

export const DISCOVER_SERVICES_BUTTON_DATA_FT_LOCATIONS = {
    title: 'Explore now',
    link: 'https://www.ftlocations.com',
}

export const COMMON_STRINGS = {
    FORMS: {
        ALL_FIELDS_REQUIRED: 'All fields are required unless indicated',
        STAY_UP_TO_DATE_TITLE: 'Stay up to date',
        STAY_UP_TO_DATE_BODY: 'Choose how you want us to contact you.',
        HAVE_QUESTIONS: 'Have a question? {0}',
        YOUR_DETAILS: 'Your details',
        PERSONALISED_OFFERS_TITLE:
            'Invites and offers from fDi Intelligence including fDi Markets and fDi Benchmark',
        PERSONALISED_OFFERS_BODY:
            'Receive personalised, carefully-curated offers, latest feature announcements and exclusive event invitations.',
        PERSONALISED_OFFERS_BODY_NEWSLETTER:
            'Receive personalised, carefully-curated offers, latest feature announcements and exclusive event invitations from {0}',
        NEWS_ALERTS_TITLE: 'Industry news alerts',
        NEWS_ALERTS_BODY:
            'Get the most from fDi Intelligence with our free personalised email service, which will keep you up-to-date with the latest industry news and our in depth features.',
        TELEPHONE_NUMBER_UK: '+44 (0)20 7775 6667',
        TELEPHONE_NUMBER_US: '+(415) 294 4774',
        FIELDS: {
            ORGANISATION_TYPE: {
                ERROR: 'Please choose an Organisation type',
            },
            HEAR_ABOUT_US: {
                ERROR: 'Please choose how you heard about us',
            },
            JOB_FUNCTION: {
                ERROR: 'Please choose a Job function',
            },
            EMAIL: {
                ERROR: 'Please enter a valid email address',
            },
            TELEPHONE: {
                ERROR: 'Please enter a valid telephone number',
                PROMPT: 'Please include your country code (e.g. for UK numbers, please include +44 before your number)',
            },
        },
    },
    DOMAINS: {
        STAGING: {
            HEROKU: 'https://release.fdiintelligence.com',
        },
    },
}

export const COOKIE_NAMES = {
    OVERLAY_HIDDEN: 'overlay_hidden',
    OVERLAY_CLOSE_INDEX: 'overlay_close_index',
    OVERLAY_CURRENT_NUMBER: 'overlay_current_number',
    FT_COOKIE_CONSENT_GDPR: 'FTCookieConsentGDPR',
    FTConsent: 'FTConsent',
    SPECIALIST_STATE: 'specialist-state',
}

export const PERMUTIVE = {
    CONFIG: {
        projectId: 'e1c3fd73-dd41-4abd-b80b-4278d52bf7aa',
        publicApiKey: 'b2b3b748-e1f6-4bd5-b2f2-26debc8075a3',
    },
    IDENTIFY_USER_TAG: 'FDI_uuid',
}

export const OVERLAY_CLOSE = 'Close'

export const EMBEDDED_TAGS_REGEX = {
    TABLE: /<div class="table-embed" data-table-id="[0-9]+"><\/div>/,
    PODCAST:
        /<div class="acastpodcast-embed" data-acastpodcast-id="[a-zA-Z0-9-]+"><\/div>/,
}

export const REGIONS = {
    URL_PARTIALS: {
        AMERICAS: 'americas',
        ASIA_PACIFIC: 'asia-pacific',
        EUROPE: 'europe',
        MIDDLE_EAST_AFRICA: 'middle-east-africa',
    },
}

export const TITLES = {
    FDI_PODCAST: 'fDi Podcast',
    RANKINGS_AND_AWARDS: 'Rankings & awards',
    REPORTS_AND_WHITEPAPERS: 'Reports and white papers',
    OPINION: 'Opinion',
    DATA_TRENDS: 'Data trends',
    INSIDE_FDI: 'Inside fDi',
    THE_GLOBAL_LAWYER: 'The Global Lawyer',
    FREE_ZONE_FOCUS: 'Free Zone Focus',
    VIEW_FROM: 'View from...',
    VIEW_FROM_WORLD: 'View from the World',
    VIEW_FROM_AMERICAS: 'View from the Americas',
    VIEW_FROM_ASIA_PACIFIC: 'View from Asia-Pacific',
    VIEW_FROM_EUROPE: 'View from Europe',
    VIEW_FROM_MIDDLE_EAST_AFRICA: 'View from Middle East & Africa',
    VIDEOS: 'Videos',
    COMMERCIAL: 'Commercial',
}

export const BRANDS = {
    FDI_ON_LOCATION: 'fDi On Location',
    FDI_IN_THE_SKY: 'fDi in the Sky',
}

export const GENERIC_LABELS = {
    SPONSORED: 'sponsored',
    ADVERTORIAL: 'advertorial',
}

export enum RANKINGS_ARTICLES_TYPE {
    RESULT = 'RESULT',
    CALL_FOR_ENTRY = 'CALL_FOR_ENTRY',
}

export const ACAST_BASE_URL = 'https://embed.acast.com/fdipodcast/'

export const EZ_OBJECT_TYPES = {
    ARTICLE_OLD: 'ArticleOld',
    ARTICLE_NEW: 'ArticleNew',
    SPECIAL_REPORT: 'SpecialReport',
    EVENT_HUB: 'EventHub',
}

export const CARD_TYPES = {
    ARTICLE: 'article',
    ADVERTORIAL: 'advertorial',
    EVENT: 'event',
    PARTNER: 'partner',
}

export const PAGE_TYPE_STREAM = 'STREAM'

export const ENQUIRY_SCHEMA_TYPES = {
    CONTACT_US_ENQUIRY: 'Contact Us Enquiry',
    DEMO_FDI_BENCHMARK: 'Demo - fDi Benchmark',
    DEMO_FDI_MARKETS: 'Demo - fDi Markets',
    DEMO_GIS_PLANNING: 'Demo - GIS Planning',
}

export const YES = 'yes'
export const NO = 'no'

export const GENRE_ARTICLE_TYPES = {
    Podcast: 'Podcast',
    Video: 'video',
    'Rankings and awards': 'rankings',
    Advertorial: 'advertorial',
}

export const BRAND_ARTICLE_TYPES = {
    Advertorial: 'advertorial',
    'Inside fDi': 'inside',
    'The Global Lawyer': 'globalLawyer',
    'Free Zone Focus': 'freezone',
    'View from Americas': 'americas',
    'View from Europe': 'europe',
    'View from Asia-Pacific': 'asia',
    'View from Middle East & Africa': 'middleEast',
}
