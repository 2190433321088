import React, { FC } from 'react'

import {
    FDI_BENCHMARK_STYLED,
    FDI_MARKETS_STYLED,
    GIS_PLANNING_STYLED,
} from '../../assets/constants'

export const POWERED_BY_OPTIONS = {
    FDI_BENCHMARK: FDI_BENCHMARK_STYLED,
    FDI_MARKETS: FDI_MARKETS_STYLED,
    GIS_PLANNING: GIS_PLANNING_STYLED,
    NONE: '',
}

const PoweredByLabel: FC<{ poweredBy: string }> = ({ poweredBy }) => {
    if (poweredBy in POWERED_BY_OPTIONS) {
        const poweredBySlug = POWERED_BY_OPTIONS[
            poweredBy as keyof typeof POWERED_BY_OPTIONS
        ]
            .toLowerCase()
            .replace(' ', '-')

        return (
            <>
                <a
                    className={`powered-by-label powered-by-label--${poweredBySlug}`}
                    href={`/${poweredBySlug}`}
                    data-trackable={'Powered By label'}
                >
                    Powered by
                    {
                        POWERED_BY_OPTIONS[
                            poweredBy as keyof typeof POWERED_BY_OPTIONS
                        ]
                    }
                </a>
            </>
        )
    }

    return null
}

export default PoweredByLabel
