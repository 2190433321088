import React, { FC } from 'react'

interface ArticleAuthorProps {
    white?: boolean
    author?: string
}

const ArticleAuthor: FC<ArticleAuthorProps> = (
    props = {
        author: '',
        white: false,
    }
) => {
    const colour = props.white ? 'white' : '$fdi-off-black'

    return (
        <div className='o-grid-row'>
            <h3
                className='article-teaser__author'
                data-o-grid-colspan='12 Mpush1 M10 L7'
                style={{
                    margin: '8px 0 24px 0',
                    color: `${colour}`,
                }}
            >
                {props.author}
            </h3>
        </div>
    )
}

export default ArticleAuthor
