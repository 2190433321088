/* eslint-disable @typescript-eslint/no-explicit-any */

import ArticleHeaderAdvertorial from '../components/article/types/ArticleHeaderAdvertorial/ArticleHeaderAdvertorial'
import ArticleHeaderVideo from '../components/article/types/ArticleHeaderVideo/ArticleHeaderVideo'
import ArticleHeaderRankings from '../components/article/types/ArticleHeaderRankings/ArticleHeaderRankings'
import ArticleHeaderWithAuthorImage from '../components/article/types/ArticleHeaderWithAuthorImage/ArticleHeaderWithAuthorImage'

const typesConfig = {
    advertorial: {
        component: ArticleHeaderAdvertorial,
        colour: 'fdi-advertorial-light',
    },
    video: {
        component: ArticleHeaderVideo,
        colour: 'fdi-off-black',
    },
    onlocation: {
        component: ArticleHeaderVideo,
        colour: 'fdi-off-black',
    },
    rankings: {
        component: ArticleHeaderRankings,
        colour: 'fdi-editorial-light',
    },
    generalOpinion: {
        colour: 'fdi-editorial-light',
        authorImageFilter: 'rgba(0, 69, 57, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    inside: {
        colour: 'fdi-editorial-light',
        authorImageFilter: 'rgba(0, 69, 57, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    globalLawyer: {
        colour: 'fdi-editorial-light',
        authorImageFilter: 'rgba(0, 130, 132, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    freezone: {
        colour: 'fdi-editorial',
        authorImageFilter: 'rgba(0, 69, 57, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    americas: {
        colour: 'fdi-locations-americas-light',
        authorImageFilter: 'rgba(143, 9, 40, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    asia: {
        colour: 'fdi-locations-asia-pacific',
        authorImageFilter: 'rgba(108, 7, 94, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    europe: {
        colour: 'fdi-locations-europe-light',
        authorImageFilter: 'rgba(0, 71, 122, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
    middleEast: {
        colour: 'fdi-locations-middle-east-light',
        authorImageFilter: 'rgba(132, 68, 19, 0.25)',
        component: ArticleHeaderWithAuthorImage,
    },
} as Record<string, any>

export default typesConfig
