/* eslint-disable camelcase */
import React, { FC } from 'react'

import ArticleBanner from '../../../article-banner/ArticleBanner'
import ArticleHeaderImage from '../../../article-header-image/ArticleHeaderImage'
import ArticleMetadata from '../../../article-metadata/ArticleMetadata'
import ArticleSocialMediaButtons from '../../../article-social-media-buttons/ArticleSocialMediaButtons'
import ArticleTitle from '../../../article-title/ArticleTitle'
import ArticleTeaserWithAuthor from '../../../article-teaser-with-author/ArticleTeaserWithAuthor'
import { Article } from '../../../../types'
import ArticleSponsors from '../../../sponsors/Sponsors'
import { getAuthorNames } from '../../../utils/article/authorNames'
import Hydration from '../../../Hydration'

interface ArticleHeaderWithAuthorImageProps {
    article: Article
    colour: string
    type: string
    authorImageFilter?: string
}

const ArticleHeaderWithAuthorImage: FC<
    ArticleHeaderWithAuthorImageProps
> = props => {
    const {
        title,
        image,
        teaser,
        author,
        advertorial_sponsor,
        publication_date,
        teaser_image_caption,
    } = props.article

    return (
        <div className='article-header-author-image'>
            <div className={`article-header article-header--${props.type}`}>
                <ArticleBanner article={props.article} colour={props.colour}>
                    <ArticleTitle
                        title={title}
                        white
                        superText={props.type || null}
                    />
                    <ArticleTeaserWithAuthor
                        teaser={teaser}
                        white
                        authorName={getAuthorNames(author)}
                        authorImage='https://static.fdiintelligence.com/assets/common/icon_author.png'
                        authorImageFilter={props.authorImageFilter}
                    />
                    <ArticleSponsors sponsors={advertorial_sponsor} />
                </ArticleBanner>
                <div className='article__social-media-container'>
                    <div className='o-grid-container'>
                        <ArticleHeaderImage
                            image={image}
                            teaser_image_caption={teaser_image_caption}
                        />
                        <Hydration id='article-social-media-buttons'>
                            <ArticleSocialMediaButtons
                                title={title}
                                teaser={teaser}
                            />
                        </Hydration>
                    </div>
                    <ArticleMetadata date={publication_date} image={image} />
                </div>
            </div>
        </div>
    )
}

export default ArticleHeaderWithAuthorImage
