/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useRef, useState } from 'react'

interface ArticleHeaderImageFullProps {
    image?: {
        image: string
    }
    caption: string
}

const ArticleHeaderImageFull: FC<ArticleHeaderImageFullProps> = (
    props = {
        image: undefined,
        caption: '',
    }
) => {
    const [marginLeft, setLeft] = useState<{ marginLeft: string | number }>({
        marginLeft: 0,
    })
    const containerRef = useRef<any>(null)

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setLeft({
                    marginLeft: `-${containerRef.current.getBoundingClientRect().left}px`,
                })
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (!props.image) {
        return null
    }

    return (
        <div className='article-header-image-full-container'>
            <div
                style={marginLeft}
                ref={containerRef}
                className='article-header__image o-grid-row'
            >
                <img
                    className='article-header__image-file'
                    alt={props.caption}
                    src={props.image.image}
                />
            </div>
        </div>
    )
}

export default ArticleHeaderImageFull
