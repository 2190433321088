/**
 * Returns a random placeholder image. max refers to the number of files within the directory
 * Would be useful to automate this at some point by counting the number of files
 * @returns {string}
 */
export const getRandomPlaceholderImage = (
    baseDir = '/assets/common/placeholder-images/',
    format = 'png'
): string => {
    const max = 4
    const random = Math.floor(Math.random() * max + 1)

    return baseDir + random + '.' + format
}
