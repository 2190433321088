import React, { FC, useEffect } from 'react'
import { Report as ReportType } from '../../types'
import ReportHeader from '../report-header/ReportHeader'
import Sponsors from '../sponsors/Sponsors'
import Attachment from '../attachment/Attachment'
import SectionTitle from '../section-title/SectionTitle'
import ArticleListItem from '../article-list-item/ArticleListItem'
import MPUContainer from '../mpu/mpu-container/MPUContainer'
import { initTracking } from '../tracking/Tracking'
import { updateAdsConfig } from '../utils/updateAdsConfig'

const SpecialReport: FC<{
    report: ReportType
}> = ({ report }) => {
    useEffect(() => {
        initTracking('report')
    }, [])

    useEffect(() => {
        updateAdsConfig({
            uuid: report.id,
            asset_type: 'special_report',
            pt: 'art',
        })
    }, [report])

    if (!report) return null

    return (
        <>
            <ReportHeader {...report} />
            <div className='o-grid-container'>
                <Sponsors sponsors={report.sponsor} colSpan='12' />
                <div className='o-grid-row'>
                    <div data-o-grid-colspan='12 L8'>
                        <Attachment {...{ ...report.attachment }} />
                        <div className='o-grid-row'>
                            <SectionTitle titleContent='Articles in this report' />
                        </div>
                        {report.articles.slice(0, 5).map(article => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                            />
                        ))}
                    </div>
                    <div data-o-grid-colspan='12 L4'>
                        <MPUContainer type='mid' />
                    </div>
                    <div data-o-grid-colspan='12 L8'>
                        {report.articles.slice(5).map(article => (
                            <ArticleListItem
                                article={article}
                                key={article.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className='bottom-ad'>
                <div
                    className='o-ads o-ads--center o-ads--placeholder'
                    data-o-ads-formats-default='false'
                    data-o-ads-formats-large='Leaderboard'
                    data-o-ads-formats-extra='SuperLeaderboard'
                    aria-hidden='true'
                />
            </div>
        </>
    )
}

export default SpecialReport
